import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
export const ThemeProvider = ({children}) => {

    const [initialized,setInitialized] = useState(false);
    const [styleTag,setStyleTag] = useState(null);
    const [textContent,setTextContent] = useState("");
    const auth = useSelector((state) => state.auth)
    useEffect(()=>{
        if(auth!==undefined && auth!==null)
        {
            if(!initialized)
            {
                var theme = auth.theme;
                let css='';
                theme.cssClasses.map((c)=>{
                    css+=c.name + '{';
                    c.cssStyles.map((s)=>{
                        css+=s.key+":" + s.value + ";"
                        return s;
                    })
                    css += '}';
                })
                let styleTag = document.createElement('style')
                styleTag.type = 'text/css'
                document.head.appendChild(styleTag);
                setStyleTag(styleTag);
                setTextContent(css);
                setInitialized(true);
            }
            else{
                var theme = auth.theme;
                let css='';
                theme.cssClasses.map((c)=>{
                    css+=c.name + '{';
                    c.cssStyles.map((s)=>{
                        css+=s.key+":" + s.value + ";"
                        return s;
                    })
                    css += '}';
                })
                setTextContent(css);
            }
        }
    },[initialized,auth])

   useEffect(()=>{
    if(styleTag!==null && textContent!=null)
    {
        styleTag.textContent=textContent
        setStyleTag(styleTag);
    }
  
   },[styleTag,textContent])

   return <div>{children}</div>
 };

 export default ThemeProvider;