import React, { useCallback, useState, createContext } from "react";
import {
    CToaster,
    CToast,
    CToastHeader,
    CToastBody
  } from '@coreui/react-pro'
import { useEffect } from "react";
const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider({ children }) {
    const [toasts, setToasts] = useState([])
    const [toastsElements,setToastElements] = useState(null);
 

  const addToast = useCallback(
    function (toast) {
        toast.position = 'bottom-right';
        toast.fade = true;
        if(toast.statusCode && toast.statusCode==='INFO')
        {
            toast.autohide = true;
            toast.delay =5000;
            toast.className='toast-success';
            toast.title = "Information";
            console.log("ToastsSet:",toast);
            setToasts((toasts) => [...toasts, toast]);
        }else
        if(toast.statusCode && toast.statusCode==='OK')
        {
            
            toast.autohide = true;
            toast.delay =3000;
            toast.className='toast-success';
            toast.title = "Success";
            //setToasts((toasts) => [...toasts, toast]);
        }else
        if(toast.statusCode && toast.statusCode==='Error')
        {
            toast.autohide = true;
            toast.delay =5000;
            toast.className='toast-error';
            toast.title = "Error";
           
            if(toast.errorMessages)
            {
              toast.body=toast.errorMessages;
            }
            setToasts((toasts) => [...toasts, toast]);
        }
        else if(toast.statusCode && toast.statusCode===404)
        {
            toast.autohide = true;
            toast.delay =10000;
    
          toast.title="Error";
          toast.className='toast-error';
          setToasts((toasts) => [...toasts, toast]);
        }
        else if(toast.statusCode && toast.statusCode===500)
        {
          if(toast.errorMessages)
          {
            toast.body=toast.errorMessages;
          }
          else{
            toast.body = JSON.stringify(toast);
          }
         
          toast.autohide = true;
            toast.delay =10000;
          toast.title="Error";
          toast.className='toast-error';
          setToasts((toasts) => [...toasts, toast]);
        } 
        else{
            toast.autohide = true;
            toast.delay =10000;
            toast.title="Error";
            toast.className='toast-error';
            setToasts((toasts) => [...toasts, toast]);
        }
    },
    [setToasts]
  );

  useEffect(()=>{
if(toasts!==null && toasts.length>0)
{
    var t = toasts[toasts.length-1];
    setToastElements(<CToast
       
        autohide={true}
        delay={t.delay}
        
        className={t.className}
      >
        <CToastHeader closeButton={t.closeButton}>
            {t.title}
        </CToastHeader>
        <CToastBody>
            {t.body}
        </CToastBody>
      </CToast>)
}
   



  },[toasts]);
 

  return (
    <ToastContext.Provider value={addToast}>
        {children}
        {
            <CToaster
                position={0}
                placement="bottom-end"
                push={toastsElements}
            >
            </CToaster>
       }
    </ToastContext.Provider>
  );
}
